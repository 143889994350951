export documentType from './documentType.json'
export nationality from './nationality.json'
export title from './title.json'
export titleEN from './titleEN.json'
export province from './province.json'
export amphur from './amphur.json'
export tambol from './tambol.json'
export relationship from './relationship.json'
export bank from './bank.json'
export dividend from './dividend.json'
export netbank from './netbank.json'
export country from './country.json'
